@import "typography";
html {
    font-size: $base-font-size;
    font-family: $poppins;
    @include bdown(md){
        font-size: 14px;
    }
}
a[href],
button {
    &:not(.disabled):not(:disabled) {
        cursor: pointer;
    }
    &.disabled,
    &:disabled {
        cursor: default;
        pointer-events: none;
    }
    transition: all .3s ease-in-out;
}
body {
    line-height: 1.25;
    font-size: 1rem;
    // color: $base;
    color: $blue;
}
.gray {
    color: $gray !important;
}
.brown {
    color: $brown !important;
}
.blue {
    color: $blue !important;
}
.white {
    color: #fff !important;
}

.blue-bg {
    background-color: $blue;
    h2 {
        color: #D3CABB;
    }
}
.green-bg {
    background-color: $green;
}
.light-blue-bg {
    background-color: $light-blue;
}
.lightest-blue-bg {
    background-color: rgba($color: $blue, $alpha: .06);
}
.light-green-bg {
    background-color: rgba($color: $green, $alpha: .14);
}
.lightest-green-bg {
    background-color: rgba($color: $green, $alpha: .06);
}
.dark-blue-bg {
    background-color: #1D235B;
}
.beige-bg {
    background-color: #EFECE6;
}
.beige-bg-alt {
    background-color: #F5F5F5;
}
.light-brown-bg {
    background-color: #D3CABB;
}
.brown-bg {
    background-color: $brown;
}
.dark-bg {
    background-color: #444;
}
.no-bg {
    background-image: none !important;
}
.underline {
    text-decoration: underline;
}
.all-caps {
    text-transform: uppercase;
}
.pr {
    position: relative;
}
.bold {
    font-weight: 700;
}
.no-pt {
    padding-top: 0;
}
.no-pb {
    padding-bottom: 0;
}
.no-p {
    padding-top: 0;
    padding-bottom: 0;
}
.uppercase {
    text-transform: uppercase;
}
.block-shadow {
    @extend %block-shadow-2;
    &--top{
        @extend %block-shadow-3;
    }
}
.double-shadow {
    @extend %double-shadow;
    .white-bordered {
        border: .5rem solid #fff;
        box-shadow: 0 0 1px #CBCBCB;
        position: relative;
        z-index: 5;
        img {
            display: block;
            z-index: 5;
        }
    }
}
h2, .h2-style {
    @include fluid-font(39px);
    line-height: 1;
    position: relative;
    @include letter-spacing(20);
    span {
        display: block;
        margin-bottom: 0.5rem;
        color: $blue;
        @include fluid-font(40px);
        font-family: $lora;
        letter-spacing: normal;
    }
}
h3 {
    @include fluid-font(30px);
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: .4em;
    font-family: $oswald;
    color: $brown;
}
p {
    margin-bottom: 1em;
}
h4 {
    @include fluid-font(30px);
    font-family: $poppins;
    font-weight: 600;
}
h5 {
    @include fluid-font(20px);
}
h6 {
    @include fluid-font(19px);
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
@include bup(md){
    .responsive {
        &-text-right {
            text-align: right;
        }
        &-text-center {
            text-align: left;
        }
    }
}

@include bup(md){
    .r-text-left {
        text-align: left;
    }
    .r-text-center {
        text-align: center;
    }
    .r-text-right {
        text-align: right;
    }
}
.flex {
    &--am {
        align-items: center;
    }
    &--auto {
        flex-basis: auto !important;
    }
}
p {
    line-height: 26px;
    &.subtitle {
        margin-bottom: .5em;
        text-align: center;
        @include letter-spacing(200);
        @include fluid-font(26px);
        color: $blue;
    }
    @include bdown(md) {
        line-height: 1.5;
    }
    // &.intro {
    //     font-family: $poppins;
    //     margin-bottom: .5em;
    //     @include font-count(17, 22);
    //     @include letter-spacing(30);
    // }
}

.decorated {
    position: relative;
    &:after {
        content: url(#{$images}/icons/decoration.svg);
        display: block;
        margin-top: .5rem;
    }
    &--white {
        &:after {
            content: url(#{$images}/icons/decoration-white.svg);
        }
    }
    &--line {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        position: relative;
        &:after {
            width: 50px;
            height: 3px;
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $green;
        }
        &--orange:after {
            background-color: $orange;
        }
        &--blue:after {
            background-color: $light-blue;
        }
        &--white:after {
            background-color: #fff;
        }
    }
}
.double-arrow {
    display: inline-flex;
    align-items: center;
    color: $blue;
    font-size: 15px;
    font-weight: 600;
    @include letter-spacing(50);

    &:hover{
        color: $light-blue;
    }
    &:after {
        content: "\e90a";
        padding-left: .5rem;
        font-family: $icomoon;
        display: inline-block;
    }
}


a[name^="eztoc"] {
    display: none !important;
}
// .text-center {
//     ul {
//         text-align: left;
//     }
// }
.btn {
    color: $brown;
    background-color: #fff;
    border: 2px solid #B4B4B4;
    @include fluid-font(14px);
    @include transition;
    padding: .9em 2em;
    font-weight: 600;
    line-height: normal;
    display: inline-flex;
    align-items: center;
    @include letter-spacing(160);
    &--x2 {
        &:before {
            font-size: 1.5em;
        }
    }
    &--alt {
        background-color: $blue;
        &:hover {
            background-color: $green;
        }
    }
    &--small {
        padding: .5em 1em;
    }
    &--block {
        display: block;
        text-align: center;
    }
    &--transparent {
        background-color: transparent
    }
    &--arrowed {
        &:after {
            content: "\e91e";
            padding-left: .5em;
            display: block;
            font-family: $icomoon;
        }
    }
    &--icon-right {
        padding-right: 4em;
        position: relative;
        &:before {
            position: absolute;
            display: block;
            right: 2em;
            top: 50%;
            transform: translateY(-50%);
            font-family: $icomoon;
        }
    }
    &:hover {
        background-color: $blue;
    }
    &.opaque {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
    
    // font-size: 18px;
}
.flex {
    &--aic {
        align-items: center;
    }
}


section {
    &:not(.news){
        ul:not([class]) {
            margin-bottom: 20px;
            margin-left: 0;
            display: inline-flex;
            flex-direction: column;
            li {
                list-style-type: none;
                position: relative;
                padding-left: 1.5em;
                &:before {
                    // content: "\e90c";
                    content: "\e947";
                    font-size: .4em;
                    font-family: $icomoon;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: inline-block;
                }
                @include bup(md){
                    line-height: 26px;
                }
            }
        }
        ol:not([class]) {
            margin-bottom: 20px;
            margin-left: 15px;
            display: inline-flex;
            flex-direction: column;
        }
        .text-block.no-dot {
            ul {
                li {
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }
    &.news {
        ul:not([class]) {
            margin-bottom: 20px;
            margin-left: 0;
            display: inline-flex;
            flex-direction: column;
            li {
                list-style-type: disc;
                padding-left: 1.5em;
            }
        }
    }
}
section a:not([class]) {
    color: $brown;
    &:hover {
        color: $blue;
    }
}

