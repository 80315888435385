.slider {
    position: relative;
    min-height: 180px;
    width: 100%;
    @include bdown(md) {
        min-height: 0px;
    }
    
}
.slick-slide {
    &:focus {
        outline: none;
    }
}
.slick-track {
    display: flex;
    align-items: center;
}
.slider-instance {
    &.init {
        opacity: 0;
        height: 80px;
    }
}
.slick-dots {
    display: block;
    li {
        display: inline-block;
        padding: 0 7px;
    }
    .slick-dot {
        width: 14px;
        height: 14px;
        background-color: #D3CABB;
        border-radius: 50%;
        position: relative;
        &:hover {
            background-color: rgba($color: #B4B4B4, $alpha: .84);
        }
    }
    .slick-active {
        .slick-dot {
            background-color: rgba($color: #B4B4B4, $alpha: .84);
        }
    }
}
.main-slider {
    @include transition;
    &.slider-instance.init {
        height: 400px;
    }
    @extend %slider-arrow-style;
    .slider-item {
        position: relative;
        background-size: cover !important;
        background-position: center top;
        background-repeat: no-repeat;
        min-height: 30vh;
        max-height: 100vh;
        // &.position-left {
        //     &:after {
        //         display: block;
        //         content: "";
        //         left: 0;
        //         width: 100%;
        //         top: 0;
        //         height: 100%;
        //         background-image: linear-gradient(to right, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
        //     }
        // }
        &.position-right {
            // &:after {
            //     display: block;
            //     content: "";
            //     left: 0;
            //     width: 100%;
            //     top: 0;
            //     height: 100%;
            //     background-image: linear-gradient(to right, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
            //     @include bup(md){
            //         background-image: linear-gradient(to left, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
            //     }
            // }
            .description .inner {
                &.empty {
                    display: none;
                }
                @include bup(md){
                    right: 0;
                }
                @include bbetween(md, lg){
                    right: 15%;
                    left: auto;
                }
            }
        }
    }
    .description {
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        min-width: 33%;
        height: 100%;
        max-width: 1360px;
        width: 100%;
        z-index: 15;
        .inner {
            position: absolute;
            // width: 47%;
            // top: 160px;
            // height: calc(100% - 160px);
            padding: 2.65rem;
            background-color: rgba($color: #D3CABB, $alpha: .9);
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .slider-title-block {
            @extend %title-block;
            // padding-top: 1rem;
            line-height: 1;
            // background-color: rgba($color: $green, $alpha: .81);
            color: #fff;
        }
        .slider-buttons-block {
            margin-top: 2rem;
        }
        // .slider-buttons-block {
        //     margin-top: .5em;
        // }
        .btn {
            color: $blue;
            &:hover {
                color: #fff;
            }
        }
    }
    
    .slick-dots {
        position: absolute;
        bottom: 3%;
        left: 10%;
        li {
            display: block;
            margin-bottom: .5rem;
        }
    }
    @include bup(md) {
        .description .inner {
            min-width: 630px;
        }
        &.no-min-height {
            .slider-item {
                min-height: 0;
            }
        }
    }
    @include bup(sm) {
        .description {
            .slider-title-block {
                p {
                    max-width: 610px;
                }
            }
        }
    }
    @media screen and (max-width: 1400px){
        .description {
            .inner {
                left: 15%;
            }
        }
    }
    @include bdown(md) {
        .slider-item {
            min-height: 60vh;
        }
        .description {
            .inner {
                width: auto;
                left: 10%;
                right: 10%;
            }
        }
        &.no-min-height {
            .slider-item {
                min-height: 200px;
            }
        }
    }
    @include bbetween(sm, lg){
        .description {
            .inner {
                padding: 1rem 2.65rem;
            }
        }
    }
    @include bdown(sm) {
        .description {
            .inner {
                padding: 1em;
            }
            .slider-buttons-block {
                margin-top: 0;
                .slider-button {
                    margin: .3em 0;
                    padding: .5em 1em;
                }
            }
        }
    }
    @include bdown(xs) {
        .description {
            .inner {
                left: 5%;
                right: 5%;
            }
            .slider-title-block {
                .title-2 {
                    padding-top: .15em;
                    padding-bottom: .15em;
                }
                p {
                    margin-bottom: 1em;
                }
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }
}

.alt-slider {
    @include transition;
    &.slider-instance.init {
        height: 400px;
    }
    @extend %slider-arrow-style-2;
    .slider-item {
        position: relative;
        background-size: cover !important;
        background-position: center top;
        background-repeat: no-repeat;
        min-height: 30vh;
        max-height: 100vh;
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to right, rgba(0, 0, 0, .7), transparent);
            z-index: 5;
        }
        &.position-left {
            &:after {
                display: block;
                content: "";
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background-image: linear-gradient(to right, rgba(0, 0, 0, .7), transparent);
            }
        }
        &.position-right {
            &:after {
                display: block;
                content: "";
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background-image: linear-gradient(to right, rgba(0, 0, 0, .7), transparent);
                @include bup(md){
                    background-image: linear-gradient(to left, rgba(0, 0, 0, .7), transparent);
                }
            }
            .description .inner {
                &.empty {
                    display: none;
                }
                @include bup(md){
                    right: 0;
                }
                @include bbetween(md, lg){
                    right: 15%;
                    left: auto;
                }
            }
        }
    }
    .description {
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        min-width: 33%;
        height: 100%;
        max-width: 1360px;
        width: 100%;
        z-index: 15;
        .inner {
            position: absolute;
            // width: 47%;
            // top: 160px;
            // height: calc(100% - 160px);
            padding: 2.65rem;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .slider-title-block {
            @extend %title-block-2;
            line-height: 1;
            .slider-titles-only {
                background-color: rgba($color: #365360, $alpha: .89);
                padding: 1rem;
                margin: 0 -1rem 2rem;
            }
            // background-color: rgba($color: $green, $alpha: .81);
            color: #fff;
            margin-bottom: 2rem;
        }
        .slider-buttons-block {
            display: flex;
            justify-content: space-between;
        }
        // .slider-buttons-block {
        //     margin-top: .5em;
        // }
        .btn {
            &:first-child {
                color: #929292;
                &:hover {
                    color: #fff;
                }
            }
            &:last-child {
                color: $blue;
                background-color: #b4b4b4;
                &:hover {
                    background-color: $blue;
                    color: #fff;
                }
            }
        }
    }
    @include bup(md) {
        &.no-min-height {
            .slider-item {
                min-height: 0;
            }
        }
    }
    @include bup(sm) {
        .description {
            .slider-title-block {
                p {
                    max-width: 493px;
                }
            }
        }
    }
    @media screen and (max-width: 1400px){
        .description {
            .inner {
                left: 15%;
            }
        }
    }
    @include bdown(md) {
        .slider-item {
            min-height: 60vh;
        }
        .description {
            .inner {
                width: auto;
                left: 10%;
                right: 10%;
            }
        }
        &.no-min-height {
            .slider-item {
                min-height: 200px;
            }
        }
    }
    @include bdown(sm) {
        .description {
            .inner {
                padding: 1em 0;
            }
            .slider-buttons-block {
                margin-top: 0;
                .slider-button {
                    margin: .3em 0;
                    padding: .5em 1em;
                }
            }
        }
    }
    @include bdown(xs) {
        .description {
            .inner {
                left: 5%;
                right: 5%;
            }
            .slider-title-block {
                .title-2 {
                    padding-top: .15em;
                    padding-bottom: .15em;
                }
                p {
                    margin-bottom: 1em;
                }
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }
    &.about-alt-slider {
        margin-bottom: 2rem;
        @extend %block-shadow-2;
    }
}

.middle-slider {
    @extend %slider-arrow-style;
    &--inner {
        display: flex;
        position: relative;
        z-index: 5;
        h4 {
            color: #fff;
            font-family: $lora;
            font-weight: 700;
            display: inline-block;
            border-bottom: 1px solid #929292;
            padding-bottom: .3em;
            margin-bottom: 1.5em;
        }
        h3 {
            font-family: $ebg;
            color: #525252;
            margin-bottom: 1em;
        }
        h2 {
            color: $blue;
        }
        p {
            @include fluid-font(16px);
        }
        .pr {
            padding-top: 2rem;
            .link-more {
                color: #fff;
                @include fluid-font(17px);
                &:hover {
                    color: $blue;
                }
            }
        }
    }
    &--inner--image {
        flex: 0 0 50%;
        position: relative;
        border: 1rem solid #fff;
        img {
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
            object-fit: cover;
        }
    }
    &--inner--info {
        flex: 0 0 50%;
        position: relative;
        background-color: #D3CABB;
        padding: 5% 10% 5% 5%;
        background-image: url(../../images/icons/loudspeaker.svg);
        background-repeat: no-repeat;
        background-position: 95% 5%;
    }
    .double-shadow-container {
        @extend %double-shadow;
        max-width: 50%;
        position: relative;
        height: 3.5rem;
        &:after {
            background-image: url(../../images/double-shadow-big.png);
            background-size: 100% 17.5rem;
            height: 17.5rem;
            bottom: -2.5rem;
        }
    }
    @include bdown(md){
        .slick-list {
            background-color: #d3cabb;
        }
        &--inner {
            flex-direction: column;
        }
        &--inner--image {
            flex: 0 0 100%;
            img {
                position: static;
                max-width: 100%;
                height: auto;
            }
        }
        .double-shadow-container {
            max-width: 100%;
            display: none;
        }
        .slick-arrow {
            top: 25%;
        }
    }
    .slick-track {
        align-items: normal;
    }
}
.events-slider {
    @include bup(md){
        &.slick-initialized {
            .slick-slide {
                display: flex;
                flex-direction: column;
                height: inherit !important;
            }
        }
        .slick-track {
            align-items: normal;
        }
    }
    
    &--inner--info {
        padding: 5% 10% 5% 2%;
    }
    h4 {
        font-size: .9rem;
        color: $base;
        font-family: $poppins;
        font-weight: 500;
        border-bottom: none;
        margin-bottom: 1rem;
        padding-bottom: 0;
        &:before {
            font-family: $icomoon;
            color: #9D8F78;
            font-size: 1.5em;
            padding-right: .5em;
            content: "\e941";
        }
    }
    &--inner--info-top {
        font-size: 1.44rem;
        color: #fff;
        font-family: $lora;
        font-weight: 700;
        padding-bottom: .5rem;
        margin-bottom: .8rem;
        &:after {
            content: "";
            height: 1px;
            background-color: #929292;
            display: block;
            width: 40%;
            margin-top: .5rem;
        }
    }
    &--inner--info-left.col {
        text-transform: uppercase;
        flex: 0 0 185px;
        margin-right: 3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &--weekday {
        font-family: $oswald;
        font-size: .72rem;
        text-transform: uppercase;
    }
    &--date {
        font-family: $oswald;
        font-size: 3.6rem;
        font-weight: 500;
        @include rlh(58px);
        line-height: 1.1;
    }
    &--month {
        font-family: $montserrat;
        font-weight: 600;
        font-size: .72rem;
        text-transform: uppercase;
    }
    &--time {
        font-family: $poppins;
        font-size: .8rem;
        padding-top: 1rem;
        margin-top: 1rem;
        color: $base;
        border-top: 2px solid rgba(146, 146, 146, .69);
    }
    @include bup(md){
        .middle-slider--inner {
            height: calc(100% - 3.5em);
        }
    }
    @include bdown(md){
        &--time {
            font-size: 1rem;
        }
        &--inner--info-top {
            &:after {
                width: 100%;
            }
        }
        // &--inner {
        //     flex-direction: column;
        // }
        // &--inner--image {
        //     flex: 0 0 50%;
        //     img {
        //         position: absolute;
        //         max-width: 100%;
        //         height: 100%;
        //     }
        // }
        .double-shadow-container {
            max-width: 100%;
        }
        .slick-arrow {
            top: 50%;
        }
    }
}

.testimonials-container {
    padding-top: 0;
    padding-bottom: 0;
    .container.fw {
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
}
.testimonials-slider {
    padding: 1rem 2rem;
    .slick-track, .slick-list {
        display: flex !important;
        // justify-content: center;
    }
    .slick-track {
        justify-content: center;
    }
    .slick-slide {
        // display: flex;
        height: 100%;
    }
    .inner {
        position: relative;
        height: 100%;
    }
    .testimonial {
        padding: 0 9%;
        position: relative;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $blue;
        @include bup(md){
            top: 0;
            transition: 1.5s ease-in-out;
        }
        @include fluid-font(15px);
        p {
            margin-bottom: 0;
        }
        .ts {
            &-text {
                margin-bottom: 1em;
                position: relative;
                font-family: $poppins;
                margin-bottom: 0;
                @include rlh(40px);
                @include fluid-font(30px);
                border-top: 4px solid $green;
                border-bottom: 4px solid $green;
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                p {
                    line-height: inherit;
                }
                &:before {
                    content: "\e903";
                    transform: rotate(-180deg);
                    display: block;
                    color: $green;
                    font-family: $icomoon;
                    position: absolute;
                    left: -5rem;
                    top: 0;
                    font-size: 3rem;
                }
                &:after {
                    content: "\e903";
                    display: block;
                    color: $green;
                    font-family: $icomoon;
                    position: absolute;
                    right: -5rem;
                    bottom: 0;
                    font-size: 3rem;
                }
            }
            &-author {
                @include fluid-font(29px);
                font-weight: 700;
                font-family: $poppins;
                @include letter-spacing(100);
                color: $green;
            }
            &-position {
                font-family: $poppins;
                @include fluid-font(18px);
                color: #000;
            }
            &-author-position {
                padding-right: 3rem;
                display: inline-flex;
                flex-direction: column;
                align-self: flex-end;
                align-items: flex-start;
            }
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: #B4B4B4;
        z-index: 100;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(20, 50);
        }
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-dots {
        text-align: center;
    }
    @include bup(md) {
        // padding-top: 16px;
        // padding-bottom: 16px;
        // padding-left: 5%;
        // padding-right: 5%;
        
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
    @include bdown(md) {
        padding: 25px 1rem;
        .testimonial {
            padding: 0 2.5rem;
        }
        // .inner {
        //     padding-left: 5%;
        //     padding-right: 5%;
        //     margin-left: 5%;
        //     margin-right: 5%;
        // }
        .testimonial .ts-text {
            &:before {
                left: -2rem;
                font-size: 2rem;
            }
            &:after {
                right: -2rem;
                font-size: 2rem;
            }
        }
    }
}
.packages {
    &--inner {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding-top: 8rem;
        img {
            position: absolute;
            bottom: calc(100% - 7rem);
            max-height: 7rem;
            left: 1.2rem;
        }
    }
    &--slider {
        @include bup(md){
            padding-left: 7%;
            padding-right: 7%;
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    &--info {
        border-left: 1px solid $blue;
        padding-left: 1rem;
        h5 {
            color: $blue;
            @include fluid-font(25px);
        }
        p {
            @include fluid-font(20px);
        }
        a {
            @include fluid-font(15px);
            color: $orange;
            font-weight: 600;
            &:after {
                content: "\e90a";
                font-family: $icomoon;
                padding-left: .5rem;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 2rem;
        color: $blue;
        &:hover {
            color: $light-blue;
        }
        &.slick-prev {
            left: 1rem;
        }
        &.slick-next {
            right: 1rem;
        }
        @include bdown(xs){
            &.slick-prev {
                left: .5rem;
            }
            &.slick-next {
                right: .5rem;
            }
        }
    }

}
.learn-how {
    &--inner {
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        img {
           
        }
    }

    &--slider {
        @include bup(md){
            padding-left: 7%;
            padding-right: 7%;
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    &--info {
        padding-left: 1rem;
        padding-top: 1rem;
        h5 {
            color: $blue;
            @include fluid-font(37px);
            font-weight: 700;
            color: $dark;
            line-height: 1;
        }
        p {
            @include fluid-font(20px);
            text-transform: uppercase;
            color: $blue;
            font-weight: 600;
            margin-bottom: 0;
        }
        a {
            @include fluid-font(15px);
            color: $orange;
            font-weight: 600;
            &:after {
                content: "\e90a";
                font-family: $icomoon;
                padding-left: .5rem;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 2rem;
        color: $blue;
        &:hover {
            color: $light-blue;
        }
        &.slick-prev {
            left: 1rem;
        }
        &.slick-next {
            right: 1rem;
        }
        @include bdown(xs){
            &.slick-prev {
                left: .5rem;
            }
            &.slick-next {
                right: .5rem;
            }
        }
    }

}