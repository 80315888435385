header {
    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;
    // z-index: 1000;
    transition: background-color .4s;
    .container {
        position: relative;
    }
    .header-top {
        padding-top: 5px;
        padding-bottom: 8px;
        position: relative;
        font-family: $poppins;
        .row {
            align-items: center;
        }
        .col {
            &:nth-child(1){
                flex: 1 1 25%;
            }
            &:nth-child(2){
                display: flex;
                flex: 1 1 auto;
                align-items: center;
                align-self: flex-start;
                justify-content: flex-end;
            }
        }
        .logo {
            display: block;
        }
        .soc-icons {
            @extend %share-style1;
        }
        .btn {
            background-color: $blue;
            color: #fff;
            letter-spacing: normal;
            white-space: nowrap;
            display: inline-flex;
            align-items: center;
            font-family: $poppins;
            @include fluid-font(14px);
            @include letter-spacing(200);
            font-weight: 500;
            justify-content: center;
            border-radius: 0;
            padding: 1rem 2rem;
            &:before {
                margin-right: .5rem;
                font-size: 1.5em;
                content: "\e93d";
                font-family: $icomoon;
            }
            &:hover {
                background-color: $brown;
            }
        }
        form {
            @include bup(xs){
                width: 350px;
            }
            @include bdown(xs){
                width: 100%;
            }
            color: $blue;
            .search {
                border-bottom: 2px solid $blue;
                display: flex;
                align-items: center;
                ::placeholder {
                    color: $blue;
                }
                input {
                    max-width: 90%;
                    border: none;
                    background-color: transparent;
                    font-weight: 500;
                    color: $blue;
                    @include fluid-font(23px);
                    padding: .5em 1em 0.2em 1em;
                    flex: 1 1 0;
                }
                button {
                    font-size: 25px;
                    background-color: transparent;
                    border: none;
                    flex: 0 0 auto;
                    padding: 0;
                    color: $blue;
                    &:hover {
                        color: $brown;
                    }
                }
            }
        }

        @include bup(md) {
            border-top: 13px solid #D3CABB;
            .top-links {
                padding-right: 7%;
            }
            .top-phone-block {
                margin-left: 5em;
            }
            .btn {
                position: absolute;
                top: -19px;
                right: 10px;
                padding: 1rem 1.9rem;
            }
        }
        @include bbetween(md, lg) {
            .btn {
                right: 3%;
            }
        }
        @include bbetween(md, nm){
            .top-links {
                flex-direction: column;
                .separator {
                    display: none;
                }
                .icon-mobile {
                    margin-bottom: .5rem;
                }
            }
        }
        @include bdown(md) {
            padding-top: 0;
            padding-bottom: 0;
            .container {
                justify-content: center;
            }
            .col {
                margin-bottom: 10px;
            }
            .col:nth-child(2) {
                justify-content: space-between;
            }
            .col:nth-child(3) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0;
            }
            .soc-icons {
                display: none;
            }
            .top-links {
                .separator, span {
                    display: none;
                }
            }
            .top-phone-block {
                display: block;
                padding-left: 0;
                span {
                    display: none;
                }
                a {
                    display: inline-block;
                }
                // & > * {
                //     display: block;
                //     margin-left: auto;
                //     margin-right: auto;
                // }
            } 
        }
    }
    .header-bottom {
        background-color: $blue;
        // .container {
        //     display: flex;
        //     align-content: center;
        // }
        // .nav-container {
        //     display: flex;
        //     justify-content: space-between;
        //     flex: 1 0 auto;
        // }
        nav {
            // width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            a {
                position: relative;
                text-transform: uppercase;
                color: #F4F4F4;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                font-family: $oswald;
                @include transition;
                line-height: 26px;
                @include fluid-font(24px);
                @include letter-spacing(100);
                font-weight: 600;
                background-color: transparent;
                padding: .4em .7em;
                &.nav-item-active {
                    color: #D3CABB;
                }
                &:hover {
                    color: #D3CABB;
                }
            }
        }
        @include bup(md){
            nav {
                padding: 10px 0;
                // a {
                //     &.nav-item-active {
                //         // border-bottom: 6px solid $green;
                //         position: relative;
                //         &:after {
                //             position: absolute;
                //             display: block;
                //             content: "";
                //             bottom: 0;
                //             left: 20%;
                //             width: 60%;
                //             height: 3px;
                //             background-color: $green;
                //         }
                //     }
                // }
            }
        }
        @include bup(lg){
            @include font-count(10, 22);
        }
        @include bdown(lg) {
            @include fluid-font(14px);
        }
        @include bdown(md) {
            font-size: 16px;
            .container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: static;
                padding-left: 0;
                padding-right: 0;
            }
            .nav-container {
                display: none;
            }
            nav {
                flex-direction: column;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
                // height: 100vh;
                justify-content: space-evenly;
                background-color: $blue;
                // background-image: linear-gradient(to right, $light-blue, $blue 80%, $blue);
                // background-image: linear-gradient(to right, rgba(0, 140, 153, .95), rgba(62, 76, 202, .95));
                a {
                    display: block;
                    font-size: 16px;
                    padding: .5em 5%;
                    &.nav-item-active, &:hover {
                        background-color: $blue;
                        color: #fff;
                        &:after {
                            content: none;
                        }
                    }
                    &:last-of-type {
                        margin-left: 0;
                    }
                    &:before {
                        content: none;
                    }
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
    span.nav-toggle {
        text-align: center;
        font-family: 'icomoon';
        font-size: 2em;
        min-width: 25px;
        display: block;
        transition: transform .3s ease;
        transform-origin: 50% 50%;
        color: $blue;
        &:before {
            content: "\e923";
        }
        &.close {
            transform: rotate(-180deg);
        }
        &.close:before {
            content: "\e922";
        }
    }
}
#logout-form {
    display: none;
}
.main-slider-loading {
    header {
        background-color: rgba(0, 0, 0, .8);
    }
}