@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700|Oswald:400,600,700|Montserrat:400,500,700|EB+Garamond:500|Poppins:500,600&display=swap');
@import "icomoon";

$lora: 'Lora', sans-serif;
$oswald: 'Oswald', sans-serif;
$poppins: 'Poppins', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$ebg: 'EB Garamond', serif;
$icomoon: "icomoon";
// $sacramento: 'Sacramento', cursive;
