.login-popup {
    display: none;
}
.login-popup-lightbox {
    .login-popup {
        display: block;
        color: #fff;
        font-family: $poppins;
        &--top {
            @include letter-spacing(100);
            @include fluid-font(25px);
            background-color: $green;
            font-weight: 700;
            padding: .5rem 3rem .5rem 1rem;
            position: relative;
            .icon-close {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                padding-left: .8rem;
                padding-right: .8rem;
                display: flex;
                align-items: center;
                background-color: #fff;
                color: $light-blue;
                &:before {
                    padding-right: 0;
                }
            }
        }
        &--bottom {
            background-color: $light-blue;
            padding: 1rem;
            @include bup(md){
                padding-left: 10%;
                padding-right: 10%;
            }
        }
    }
    .featherlight-content {
        background: transparent;
        border: transparent;
        @include bup(md){
            width: 724px;
        }
        @include bdown(md){
            width: 70%;
            min-width: 300px;
        }
    }
}
.block-shadow {
    @extend %block-shadow-2;
    &--top{
        @extend %block-shadow-3;
    }
}
.bordered-img-container {
    display: inline-block;
    img {
        position: relative;
        border: .625rem solid #fff;
        box-shadow: 0 0 4px #cbcbcb;
        z-index: 5;
    }
}
.sign-up {
    color: #fff;
    background-image: url(../../images/icons/envelope.svg);
    background-repeat: no-repeat;
    background-position: 100% 107%;
    background-size: auto;
    .row {
        align-items: center;
        h3 {
            text-align: left;
            color: inherit;
            @include fluid-font(36px);
            line-height: 1;
            & + p {
                @include fluid-font(16px);
                font-weight: 500;
                color: $brown;
            }
        }
    }
    form {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .input-container {
        position: relative;
        border-radius: 5px;
        margin-right: .5em;
        background-color: #fff;
        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            color: #D3CABB;
            font-size: 1.3em;
        }
    }
    input {
        background: transparent;
        border: none;
        color: #A1A1A1;
        flex: 1 1 0;
        padding: 0 4em 0 1em;
        height: 100%;
        font-family: $poppins;
        width: 100%;
        @include font-count(13, 18);
    }
    button.btn {
        border: none;
        @include fluid-font(13px);
        @include transition;
        white-space: nowrap;
        background-color: #363636;
        color: #fff;
        &:hover {
            color: $brown;
        }
    }
    @include bup(lg){
        .input-container {
            min-width: 70%;
        }
    }
    @include bup(md) {
        .col:nth-child(1){
            padding-left: 7%;
        }
        .input-container {
            min-width: 60%;
            margin-right: 2em;
        }
        background-size: auto 90%;
        background-position: 88% 50%;
    }
    @include bdown(md) {
        .row h3, .row h3 + p {
            text-align: center;
        }
    }
}
.book-top-bg {
    background-image: url(../../images/icons/open-book.svg);
    background-repeat: no-repeat;
    background-position: left -5%;
}
.book-top-bg-alt {
    background-image: url(../../images/icons/open-book.svg);
    background-repeat: no-repeat;
    background-position: left 0%;
}
.calendar-top-bg {
    background-image: url(../../images/icons/calendar-for-bg.svg);
    background-repeat: no-repeat;
    background-position: right 3%;
}
.icon-links {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 1rem; 
    li {
        display: flex;
        justify-content: space-between;
    }
    .icon-link {
        display: inline-flex;
        align-items: baseline;
        font-family: $oswald;
        @include fluid-font(27px);
        border: 2px solid #D3CABB;
        white-space: nowrap;
        padding: .5em 1.5em;
        &--about {
            @include fluid-font(17px);
            &:before {
                font-size: 1.3em;
            }
        }
        &:before {
            padding-right: .5em;
            font-size: 1.1em;
            font-weight: 400;
        }
        span {
            display: block;
            text-align: center;
            text-transform: uppercase;
            @include letter-spacing(160);
            color: $blue;
            @include transition;
        }
        &:hover {
            background-color: $brown;
            &:before {
                color: #fff;
            }
            span {
                color: #fff;
            }
        }
    }
    &.custom-icon-links {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        .icon-link.btn {
            flex-direction: row-reverse;
            padding: 0.5rem 3rem;
            justify-content: center;
            align-items: center;
            span {
                color: $brown;
            }
            &:hover {
                span {
                    color: #fff;
                }
            }
            &:before {
                padding-left: 1rem;
                padding-right: 0;
                font-size: 2em;
            }
        }
    }
    @include bdown(md){
        flex-wrap: wrap;
        justify-content: center;
        li {
            flex: 0 0 100%;
            margin-bottom: 1rem;
            .icon-link {
                width: 100%;
                justify-content: center;
            }
        }
    }
    @include bdown(xxs){
        li {
            flex: 0 0 100%;
        }
    }
    &--offset {
        li {
            &.standout {
                .icon-link {
                    .img-container {
                        flex-direction: column;
                    }
                    img {
                        padding: 0;
                    }
                    span {
                        position: static;
                        color: #fff;
                        @include fluid-font(31px);
                        font-weight: 700;
                    }
                }
            }
            @include bup(md){
                margin: 0 2% 2%;
                &.standout {
                    flex: 0 0 20%;
                    margin: 0 5% 2% 15%;
                }
            }
        }
    }
    &.alt {
        .icon-link {
            align-items: center;
            img {
                margin-right: 1rem;
                height: 1.5em;
            }
            &:hover {
                img {
                    filter: brightness(10);
                }
            }
        }
    }
}
.ask-librarian {
    .double-shadow {
        display: inline-block;
        img {
            position: relative;
            z-index: 5;
        }
    }
    @include bdown(md){
        padding-bottom: 0 !important;
        .col {
            margin-bottom: 0;
        }
        .col.basis-30 {
            display: none !important;
        }
    }
}
.filter-container {
    .filter {
        display: flex;
        .filter-header {
            margin-right: 2%;
            padding-right: 2%;
            @include fluid-font(33px);
            font-family: $oswald;
            color: #9D8F78;
            display: flex;
            align-items: center;
            border-right: 2px solid #9D8F78;
        }
        .filter-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex-grow: 1;
            li {
                flex: 0 0 23%;
                min-width: 23%;
                margin-right: 2%;
                margin-bottom: 1rem;
                a {
                    border: 2px solid #D3CABB;
                    padding: .5rem 1rem;
                    height: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    width: 100%;
                    text-align: center;
                    color: #929292;
                    @include fluid-font(18px);
                    &:hover,
                    &.active {
                        color: #fff;
                        border-color: #9D8F78;
                        background-color: #9D8F78;
                    }
                }
            }
        }
        @include bdown(md){
            .filter-list {
                li {
                    flex: 0 0 31%;
                }
            }
        }
        @include bdown(xs){
            .filter-list {
                li {
                    flex: 0 0 100%;
                    margin-right: 0;
                }
            }
        }
    }
}
.sliding-info-blocks {
    h2 + p {
        text-align: center;
        font-family: $poppins;
        font-weight: 600;
        text-align: center;
        @include font-count(14, 18);
        margin-bottom: 2em;
    }
    .row {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .col {
        // flex: 0 1 30%;
        margin-bottom: 30px;
        @extend %double-shadow;
    }
    .sa-item {
        overflow: hidden;
        position: relative;
        display: block;
        border: .625rem solid #fff;
        box-sizing: content-box;
        z-index: 5;
        box-shadow: 0 0 1px #CBCBCB;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
        .sliding-content {
            position: absolute;
            top: 46%;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            z-index: 10;
            /*
            recent updates
            */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include transition;
            .sa-title {
                font-family: $oswald;
                //@include font-count(28, 34);
                @include font-count(30, 48);
                @include transition;
                color: #fff;
                line-height: 1;
                font-weight: 500;
                padding-bottom: .5em;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                padding-left: 1rem;
                padding-right: 1rem;
            }
            .sa-excerpt {
                color: #fff;
                font-family: $poppins;
                @include font-count(12, 14);
                line-height: 1.2;
                font-weight: 500;
            }
            .btn {
                white-space: nowrap;
            }
        }
        &:after {
            content: "";
            display: block;
            background-image: linear-gradient(to bottom, transparent 0, rgba(#282C38, $alpha: .81) 25%, rgba(#282C38, $alpha: .81) 100%);
            position: absolute;
            top: 50%;
            height: 200%;
            left: 0;
            right: 0;
            @include transition;
            z-index: 5;
        }
        &:hover {
            .sliding-content {
                top: 0;
            }
            &:after {
                top: -100%;
            }
        }
    }
    @include bdown(nm){
        .sa-item {
            &:hover {
                .sliding-content {
                    top: 15%;
                    .sa-title {
                        white-space: normal;
                    }
                }
            }
        }
    }
    @include bdown(md) {
        .row {
            display: flex;
        }
        .sa-item {
            &:hover {
                .sliding-content {
                    top: 25%;
                }
            }
            .sliding-content {
                top: 0;
            }
            &:after {
                top: -100%;
            }
        }
    }

    &.location-blocks {
        .sa-item {
            &:hover {
                .sliding-content {
                    top: 0%;
                    .sa-excerpt {
                        opacity: 1!important;
                    }
                }
            }
            .sliding-content {
                top: 65%;
                min-width: 85%;
                // @include bdown(md){
                //     top: 10%;
                // }
                .sa-excerpt {
                    @include fluid-font(20px);
                    color: #D3CABB;
                    padding-bottom: .5rem;
                    margin-bottom: .5rem;
                    border-bottom: 1px solid #9D8F78;
                    @include transition;
                    opacity: 0;
                }
                .sa-phone {
                    color: #fff;
                    margin-bottom: .5rem;
                    &:before {
                        margin-right: .5rem;
                    }
                }
            }
            @include bbetween(nm, lg){
                .sliding-content {
                    .sa-title {
                        @include font-count(20, 30);
                    }
                }
            }
            @include bbetween(md, nm){
                .sliding-content {
                    top: 60%;
                }
            }
            @include bdown(md){
                .sliding-content {
                    top: 0;
                }
                &:after {
                    top: -100%;
                }
                .sliding-content {
                    .sa-excerpt {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.fixed-height {
        @include bup(md){
            .sa-item{
                height: 290px;
            }
        }
    }
}
.locations-aside {
    @include bup(md){
        margin-left: 5%;
    }
    &--contact-info {
        font-family: $oswald;
        p {
            margin-bottom: 5px;
            color: $blue;
            @include fluid-font(25px);
            &:first-of-type {
                color: $brown;
                @include fluid-font(28px);
            }
        }
    }
    &--banner {
        @extend %aside-banner;
        @include bup(lg){
            min-width: 410px;
        }
    }
    &--person-contacts{
        &--list {
            li {
                margin-bottom: 2rem;
            }
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:after {
                    content: "\e906";
                    display: block;
                    font-family: $icomoon;
                    font-size: 1.5rem;
                    color: $brown;
                    padding: .5rem 0 .5rem .5rem;
                    border-left: 1px solid $brown;
                    @include transition;
                }
                .inner {
                    @include bup(md){
                        flex: 0 0 70%;
                    }
                }
                span {
                    display: block;
                    &:first-child {
                        font-family: $oswald;
                        color: $brown;
                        @include fluid-font(28px);
                    }
                    &:last-child {
                        @include fluid-font(25px);
                        color: $blue;
                    }
                }
                &:hover {
                    &:after {
                        color: $blue;
                    }
                }
            }
        }
    }
}
.location-blocks-container {
    @include bbetween(md, nm){
        & > .row {
            display: block;
        }
    }
}
.branches-and-contact {
    @include bup(md){
        .d-flex,
        .d-flex--all{
            flex-wrap: nowrap;
        }

    }
}
.lib-info {
    @include bdown(sm){
        flex: 1 0 100% !important;
    }
    &--image {
        margin-right: 1rem;
        min-width: 25%;
    }
    &--text {
        color: $blue;
        padding-left: .5rem;
        border-left: 1px solid $brown;
        span {
            display: block;
            margin-bottom: .5rem;
        }
    }
    
}
.upcoming-events {
    display: flex;
    align-items: center;
    & > img {
        max-width: 40%;
        margin-top: -4rem;
    }
    .inner {
        padding-left: 1rem;
    }
    h3 {
        @include fluid-font(27px);
    }
    h4 {
        @include fluid-font(23px);
        font-family: $lora;
        color: $blue;
    }
    @include bup(md){
        border-left: 1px solid #D9D5C9;
    }
    @include bdown(md){
        & > img {
            max-height: 8rem;
        }
    }
}
.events-list-bg-container {
    background-image: url(../../images/icons/calendar-for-bg.svg);
    background-repeat: no-repeat;
    background-position: 95% 100px;
    .events-list {
        background-color: rgba($color: #000000, $alpha: .04);
    }
}
.events-list {
    .select-period {
        position: relative;
        a:not(.active){
            position: absolute;
            top: 100%;
            transform: translate(0, -50%);
            left: 0;
            color: $blue;
            display: inline-flex;
            align-items: center;
            @include letter-spacing(200);

            i {
                margin: 0 .25rem;
                font-size: .875rem;
            }
            span {
                font-size: .875rem;
                font-family: $poppins;
                margin-bottom: 0;
            }
        }
    }
    @include bdown(md){
        .select-period {
            padding-bottom: 1rem;
            a.active {
                cursor: default !important;
            }
            a:not(.active){
                top: 100%;
                transform: translate(-50%, -50%);
                right: auto;
                left: 50%;
                width: 100%;
            }
        }
    }
    div[class*="hidden-by"]{
       display: none !important;
    }
    .af {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0;
        flex-wrap: wrap;
        &--subsection {
            display: inline-flex;
            align-items: center;
            margin-right: 1rem;
        }
        &--links {
            a {
                display: inline-flex;
            }
        }
        &--title {
            font-family: $poppins;
            font-size: 14px;
            font-weight: 600;
            @include letter-spacing(200);
        }
        &--reset {
            font-family: $poppins;
            font-size: 9px;
            @include letter-spacing(160);
            font-weight: 600;
            margin-right: 1rem;
        }
        &--go {
            padding: 0 1rem;
            height: 32px;
            align-items: center;
        }
        input, select {
            font-size: 9px;
            @include letter-spacing(160);
            color: #929292;
            border: 2px solid #D3CABB;
            font-weight: 600;
        }
        input {
            height: 32px;
            padding: 0 1.5em;
            margin-left: 1rem;
        }
        .select-wrapper {
            height: 32px;
            position: relative;
            border: 2px solid #D3CABB;
            margin-left: 1rem;
            box-sizing: content-box;
            @include fluid-font(20px);
            &:before {
                position: absolute;
                right: .2em;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.5em;
                z-index: 10;
                pointer-events: none;
                color: #fff;
                font-family: $icomoon;
                content: "\e920";
            }
            &:after {
                position: absolute;
                display: block;
                content: "";
                right: 0;
                top: 0;
                width: 30px;
                height: 100%;
                background-color: $blue;
                font-size: 2em;
                z-index: 5;
                pointer-events: none;
            }
        }
        select {
            height: 32px;
            padding: 0 1.5em;
            min-width: 166px;
            border-radius: 0;
            border: none;
            -webkit-appearance: none;
        }
        @include bup(lg){
            max-width: 90%;
            margin-left: auto;
        }
        @include bdown(lg){
            &--subsection {
                flex: 1 1 100%;
                margin-bottom: 1rem;
                margin-right: 0;
                .select-wrapper{
                    select {
                        min-width: 199px;
                    }
                }
                input {
                    min-width: 232px;
                }
            }
            &--title {
                min-width: 120px;
            }
        }
        @include bdown(sm){
            &--subsection {
                display: block;
                .select-wrapper{
                    margin-left: 0;
                    margin-bottom: .5rem;
                    select {
                        box-sizing: border-box;
                        width: 100%;
                    }
                }
                input {
                    margin-left: 0;
                    margin-bottom: .5rem;
                    width: 100%;
                }
            }
        }
    }
    .no-elements {
        @include fluid-font(19px);
        font-weight: 700;
        color: $blue;
        &.hidden {
            display: none;
        }
    }
}
.branch-person {
    &--position {
        @include fluid-font(24px);
        color: $blue;
        font-weight: 500;
    }
    &--name {
        font-family: $lora;
        @include fluid-font(36px);
        color: $brown;
    }
}
.branch-features {
    ul {
        @include fluid-font(25px);
        color: $blue;
        font-weight: 600;
        
        @include bup(md){
            li {
                line-height: 30px !important;
            }
        }
    }
}

.library-comment-container {
    background-color: #D3CABB;
    @extend %block-shadow-2;
}

.search-catalogue--info {
    color: $blue;
    a {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        &:after {
            content: "\e91b";
            display: inline-flex;
            width: 2rem;
            height: 2rem;
            justify-content: center;
            background-color: rgba($color: #365360, $alpha: .73);
            color: #fff;
            font-family: $icomoon;
            margin-left: 1rem;
        }
    }
    .col.text-right {
        p {
            margin-bottom: 0;
            line-height: 1;
        }
    }
}
.questions {
    .arrowed-link {
        @include letter-spacing(160);
        @include fluid-font(18px);
    }
    .pretitle {
        @include fluid-font(22px);
        font-weight: 600;
        color: $brown;
    }
    h3 {
        @include fluid-font(28px);
        color: $blue;
        @include letter-spacing(20);
    }
    .info {
        @include fluid-font(17px);
        @include bup(md){
            margin-bottom: 1.5rem;
        }
    }
    &--container {
        &:before,
        &:after {
            background-color: $brown;
            display: block;
            content: "";
            width: 80%;
            height: 1px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &--list li {
        @include bup(md){
            border-right: 1px solid $brown;
            &:last-child {
                border-right: none;
            }
        }
        @include bdown(md){
            margin-bottom: 2.5rem;
        }
    }
    &--cant-find-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1rem;
        @include bdown(md){
            flex-wrap: wrap;
        }
        .separator {
            font-family: $lora;
            @include fluid-font(30px);
            margin-left: 2rem;
            margin-right: 2rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            position: relative;
            color: $brown;
            &:before,
            &:after {
                width: 1px;
                content: "";
                background-color: $brown;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                height: 15px;
            }
            &:before {
                top: 0;
            }
            &:after {
                top: calc(100% - 10px);
            }
            @include bdown(md){
                flex: 1 1 100%;
                text-align: center;
            }
        }
        a {
            display: inline-flex;
            align-items: center;
            color: $blue;
            @include fluid-font(27px);
            font-family: $oswald;
            img {
                width: 3.9rem;
                height: 3.9rem;
                padding-right: .5rem;
            }
        }
    }
    &--cant-find-note {
        @include fluid-font(15px);
        font-weight: 500;
        color: $blue;
        @include bup(md){
            margin-left: 1rem;
            max-width: 290px;
        }
        @include bdown(md){
            flex: 1 1 100%;
            text-align: center;
        }
    }
}
.text-image {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    .img-side {
        flex: 0 0 49%;
        .img-container {
            background-color: #F4F4F4;
            min-height: 168px;
            position: relative;
            img {
                position: absolute;
                max-width: 90%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .text-side {
        flex: 0 0 49%;
        color: $blue;
    }
    .link-more {
        text-transform: uppercase;

    }
}
.backlink {
    display: inline-flex;
    font-size: 14px;
    &:before {
        content: "\e912";
        margin-right: .5rem;
        font-family: $icomoon;
    }
    color: $blue;
    &:hover {
        color: $brown;
    }
}
.aside-banner {
    @extend %aside-banner;
}
.quicker-response {
    color: #fff;
    span {
        display: block;
    }
    
    &--title {
        @include fluid-font(40px);
        color: #D3CABB;
        font-family: $oswald;
        a {
            color: inherit !important;
        }
    }
    &--text {
        font-weight: 500;
        @include fluid-font(26px);
        font-family: $lora;
        font-style: italic;
    }
    @include bup(md){
        .col:first-child {
            border-right: 1px solid #929292;
        }
        .col {
            padding: 0 7%;
        }
    }
}
.faq {
    .acc-tab {
        background-color: rgba($brown, $alpha: .13);
        border: 2px solid rgba(#F7EFDA, $alpha: .07);
        margin-bottom: 1em;
        cursor: pointer;
        .acc-text {
            @include font-count(13, 18);
            padding-top: 1em;
            @include bup(md){
                line-height: 29px;
            }
            .btn {
                display: inline-flex;
                align-items: center;
                &:after {
                    content: "\e91e";
                    margin-left: .5rem;
                    font-family: $icomoon;
                }
            }
        }
        .acc-title {
            color: $blue;
            @include fluid-font(25px);
            @include transition;
            font-weight: 600;
            padding-left: 28px;
            padding-right: 6rem;
            min-height: 4.84rem;
            display: flex;
            align-items: center;
            position: relative;
            &:after {
                @include transition;
                content: "+";
                display: flex;
                position: absolute;
                font-size: 62px;
                width: 4.84rem;
                height: 4.84rem;
                color: #fff;
                background-color: $brown;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                // height: 100%;
                cursor: pointer;
                @include bdown(xs) {
                    font-size: 40px;
                    width: 50px;
                }
            }
            &:hover:after {
                background-color: $blue;
            }
            &.accordion-active {
                &:after {
                    background-color: $base;
                    content: "-";
                    max-height: 4.84rem;
                }
            }
        }
        .acc-text {
            padding-left: 28px;
            padding-right: 4.84rem;
            padding-bottom: 1em;
            @include fluid-font(18px);
            font-weight: 600;
            color: $blue;
        }
    }
}
section {
    .service {
        background-color: rgba(#D3CABB, $alpha: .66);
        padding-top: 3rem;
        padding-bottom: 2rem;
        .link-more {
            color: #9D8F78;
            &:hover {
                color: $blue;
            }
        }
        h3 {
            @include fluid-font(40px);
        }
        h4 {
            @include fluid-font(30px);
            color: #817A6E;
            font-family: $poppins;
        }
        &--info {
            @include fluid-font(23px);
            ul {
                li {
                    @include rlh(30px);
                }
            }
        }
        &--video-container {
            height: 0;
            width: 100%;
            position: relative;
            padding-top: 56.25%;
            iframe {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        &--image-container {
            img {
                width: 100%;
            }
        }
        &--info--excerpt {
            color: $blue;
            @include bup(md){
                max-width: 710px;
            }
            ol {
                li {
                    margin-bottom: 1rem;
                }
            }
        }
        &--bottom {
            color: $blue;
            padding-top: 1rem;
            border-top: 2px solid $brown;
            .inner {
                @include bup(md){
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &--contact {
                div span {
                    display: block;
                }
            }
        }
        &--buttons {
            text-align: right;
            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 60%;
                &.btn-download-pdf {
                    &:after {
                        content:"\e924";
                        margin-left: .5rem;
                        font-family: $icomoon;
                        font-weight: 400;
                        @include fluid-font(28px);
                    }
                }
                @include bup(md){
                    min-width: 320px;
                    height: 65px;
                }
            }
        }
    }
}
.policies-banner {
    align-items: center;
    h2 {
        color: #D3CABB;
        border-bottom: 1px solid #D3CABB;
    }
    @include bup(md){
        .col:first-child{
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        h2 {
            margin-right: 5%;
            margin-bottom: 0;
            padding-bottom: .4em;
        }
        &--info {
            @include fluid-font(33px);
            font-family: $lora;
            font-style: italic;
            max-width: 443px;
            color: #D3CABB;
        }
        .btn {
            color: $blue;
            &:hover {
                color: $brown;
            }
        }
    }
}
.circle-icons {
    h2 {
        font-family: $lora;
        @include fluid-font(48px);
        color: $blue;
    }
    .col {
        margin-bottom: 4rem;
    }
    &--img-container {
        max-width: 40%;
    }
    &--info-container {
        padding-left: 2rem;
        .link-more {
            color: $brown;
            font-weight: 600;
            @include letter-spacing(200);
            &:hover {
                color: $blue;
            }
        }
        span {
            display: block;
            &:nth-of-type(1){
                font-family: $oswald;
                @include fluid-font(39px);
                color: $blue;
                @include letter-spacing(20);
            }
            &:nth-of-type(2){
                font-family: $lora;
                @include fluid-font(21px);
                color: $brown;
                font-weight: 700;
                @include letter-spacing(20);
            }
        }
    }
}

section.tcc {
    .row {
        &.img-left {
            .tc-img {
                order: 1;
            }
            .tc-text-outer {
                order: 2;
                background-position: 90% center;
            }
            // & > .col:nth-child(1) {
            //     @extend %block-shadow-2;
            // }
        }
        &.img-right {
            .tc-img {
                order: 2;
            }
            .tc-text-outer {
                order: 1;
                background-position: 10% center;
            }
            // & > .col:nth-child(2) {
            //     @extend %block-shadow-2;
            // }
        }
    }
    .col {
        padding-left: 0;
        padding-right: 0;
        flex: 1 1 50%;
    }
    .tc-img {
        position: relative;
        border: 1rem solid #fff;
        box-shadow: 0 0 1px #BFBFBF;
        img {
            display: block;
        }
    }
    .tc-list {
        .col {
            display: flex;
            align-items: center;
            @include fluid-font(29px);
            font-weight: 700;
            padding: 0 1rem;
            padding-top: 1rem;
            a {
                display: flex;
                color: #fff;
                align-items: center;
                &:hover {
                    filter: brightness(.8);
                }
            }
            img {
                margin-right: 1rem;
            }
            @include bup(md){
                &:first-of-type {
                    border-right: 1px solid rgba(#D1D1D1, $alpha: .37);
                }
            }
            @include bdown(md){
                img {
                    max-width: 20%;
                }
            }
        }
    }
    .tc-text-outer {
        background-color: #D3CABB;
    }
    .tc-text {
        color: #6F8379;
        padding: 4em 9% 3em;
        @include font-count(14, 18);
        margin-bottom: 0;
        h3 {
            @include fluid-font(48px);
            font-family: $lora;
            color: $blue;
            display: inline-block;
            text-align: left;
            margin-bottom: 2rem;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50%;
                height: 1px;
                background-color: #929292;
            }
        }
        h5.subtitle {
            color: #fff;
            text-align: left;
            font-family: $montserrat;
            @include fluid-font(22px);
            @include letter-spacing(200);
            margin-bottom: 1.5em;
            @include rlh(26px);
        }
        p {
            line-height: 28px;
            &:last-of-type {
                margin-bottom: 2em;
            }
        }
        .icon {
            color: $orange;
            margin-left: 1em;
        }
        .btn {
            @include fluid-font(14px);
            @include letter-spacing(160);
            font-weight: 400;
            padding-left: 2rem;
            padding-right: 2rem;
            color: $blue;
            span {
                font-weight: 600;
            }
            &:before {
                padding-right: 1rem;
                font-size: 2.5em;
                color: #D3CABB;
            }
            &:hover {
                color: $brown;
            }
        }
    }
    img.img-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        @include bdown(md){
            position: static;
        }
    }
}
.policies {
    &--list {
        li {
            padding-left: 5%; 
            padding-right: 5%; 
            margin-bottom: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include bup(md){
                border-right: 3px solid $brown;
                &:nth-of-type(3n) {
                    border: none;
                }
            }
        }
    }
    .backlink-container {
        padding-left: 5%;
    }
}
.download-doucument-list {
    li {
        padding-left: 2%;
        padding-right: 2%;
        .inner{
            position: relative;
            &:hover {
                span {
                    color: $blue;
                }
            }
            & > a {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            span {
                // @include fluid-font(26px);
                @include font-count(13, 26);
                font-weight: 600;
                display: block;
                text-align: center;
                color: $brown;
                margin-left: -2%;
                margin-right: -2%;
                @include transition;
                &:after {
                    font-weight: 400;
                    content: "\e940";
                    font-family: icomoon;
                    font-size: 1.5em;
                    padding-left: .5em;
                }
            }
        }
    }
    .img-container {
        height: 350px;
        position: relative;
        &.empty {
            background-color: #000;
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            // object-fit: cover;
            object-fit: contain;
            left: 0;
            top: 0;
        }

    }
}
.person-links {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    li {
        display: flex;
        padding: .5rem 1rem;
        background-color: rgba(211, 202, 187, .5);
        flex: 0 0 49%;
        margin-bottom: 2%;
        &:first-of-type {
            flex: 1 0 100%;
            max-width: 50%;
            background-color: $blue;
            .info {
                & > span {
                    color: #fff;
                    &.name {
                        color: #D3CABB;
                    }
                }
            }
        }
        .img-container {
            flex: 0 0 49%;
        }
        .info {
            flex: 0 0 49%;
            padding-left: 1rem;
            span {
                display: block;
            }
            & > span {
                color: $blue;
                &.name {
                    font-family: $oswald;
                    @include fluid-font(34px);
                    color: #817A6E;
                }
                &.position {
                    font-weight: 600;
                    @include fluid-font(25px);
                }
                &.term {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    span:first-child {
                        font-weight: 700;
                    }
                }
            }
        }
        @include bup(md){
            &:nth-of-type(2n){
                margin-right: 2%;
            }
        }
        @include bdown(md){
            flex: 1 0 100%;
            &:first-of-type {
                max-width: 100%;
            }
        }
        @include bdown(xs){
            flex-direction: column;
        }
    }
}
.board-minutes {
    li {
        text-align: center;
    }
    a.btn {
        margin-bottom: 2rem;
        font-family: $oswald;
        padding: .5rem 2rem;
        font-weight: 400;
        color: $blue;
        @include letter-spacing(39);
        @include fluid-font(16px);
        width: 90%;
        justify-content: space-between;
        &:after {
            content: "\e93c";
            font-family: $icomoon;
            font-size: 2em;
            padding-left: .5rem;
            color: $brown;
        }
        &:hover {
            color: $brown;
        }
    }
    &.board-minutes-inner {
        a.btn {
            font-family: $poppins;
            @include fluid-font(19px);
            color: $brown;
            min-width: 95%;
            justify-content: space-between;
            &:after {
                content: "\e933";
                font-family: $icomoon;
                font-size: 1em;
                padding-left: .5rem;
                color: $brown;
            }
        }
    }
}

.yearbook {
    & > .container {
        &:first-of-type{
            border-bottom: 3px solid $brown;
            margin-bottom: 2rem;
        }
    }
    h3 {
        color: $blue;
        margin-bottom: 0.3rem;
    }
    &--download-list {
        margin-left: auto;
        margin-right: auto;
        li {
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .select-wrapper {
        height: 2.7rem;
        position: relative;
        width: 188px;
        background-color: #EAE5DC;
        border: 1px solid #9B918A;

        @include fluid-font(19px);
        &:before {
            position: absolute;
            content: "\e920";
            right: .4em;
            top: 50%;
            transform: translateY(-50%);
            font-size: 2em;
            z-index: 10;
            pointer-events: none;
            color: #fff;
            font-family: $icomoon;
        }
        &:after {
            position: absolute;
            display: block;
            content: "";
            right: 0;
            top: 0;
            width: 1.5em;
            height: 100%;
            background-color: $brown;
            font-size: 2em;
            z-index: 5;
            pointer-events: none;
        }
    }
    select {
        height: calc(2.7rem - 2px);
        padding: 0 1.5em;
        border-radius: 0;
        width: 100%;
        border: none;
        -webkit-appearance: none;
        background-color: transparent;
        color: $blue;
        @include fluid-font(25px);
        font-weight: 700;
    }
    .btn-container {
        .btn {
            height: 100%;
        }

    }
}

.apply {
    color: #fff;
    @include fluid-font(25px);
    font-weight: 600;
    .col:first-child {
        @include letter-spacing(100);
    }
    .btn {
        &:hover {
            background-color: $light-blue;
        }
    }
    @include bup(md){
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .small-text {
        @include fluid-font(21px);
        @include letter-spacing(100);
    }
}
.horizontal-block-list.calendar-top-bg {
    @include bup(md){
        .hor-block--info {
            max-width: calc(100% - 135px);
        }
    }
    .hor-block--excerpt {
        max-height: 200px;
        overflow: hidden;
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 75px;
        }
    }
    .hor-block {
        &:nth-child(odd){
            background-color: #d7d7d7;
            .hor-block--excerpt:after {
                background-image: linear-gradient(to bottom, rgba(215, 215, 215, 0.12), rgba(215, 215, 215, 1));
            }
         }
        &:nth-child(even){
            .hor-block--excerpt:after {
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, .12), rgba(255, 255, 255, 1));
            }
        }
    }
}
.hor-block {
    border-bottom: 9px solid rgba(#707070, $alpha: .09);
    &.row {
        margin-left: auto;
        margin-right: auto;
    }
    .col {
        padding: 0;
    }
    &--media-wrapper {
        width: 100%;
        position: relative;
        height: 100%;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        img {
            // object-fit: cover;
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            left: 50%;
            @include bup(md){
                top: 50%;
                transform: translate(-50%, -50%);
            }
            @include bdown(md){
                top: 0;
                transform: translate(-50%, 0%);
            }
        }
    }
    &--date {
        display: block;
        align-items: center;
        font-weight: 700;
        margin-bottom: 1rem;
        margin-right: 3rem;
        @include fluid-font(15px);
        @include letter-spacing(100);
        color: $blue;
        // &:before {
        //     content: "\e907";
        //     margin-right: 1em;
        //     font-family: $icomoon;
        //     font-weight: 400;
        // }
        span {
            display: block;
            text-align: center;
            white-space: nowrap;
            &.time {
                text-transform: uppercase;
                border-top: 1px solid #929292;
                color: #505050;
                font-weight: 500;
                padding-top: .5rem;
                margin-top: 1rem;
            }
            &.num {
                font-family: $oswald;
                font-weight: 500;
                @include fluid-font(65px);
            }
            &.month {
                font-family: $montserrat;
                font-weight: 600;
                font-size: 13px;
            }
        }
        @include bdown(md){
            margin-right: 0;
        }
    }
    &--place {
        color: $blue;
        font-family: $montserrat;
        padding-bottom: .5rem;
        max-width: 80%;
        @include fluid-font(16px);
        border-bottom: 1px solid #B9E3F5;
    }
    &--title {
        padding: .5rem 0;
        margin: 0 0 .8rem;
        font-family: $oswald;
        @include fluid-font(37px);
        font-weight: 500;
        color: $blue;
        text-transform: uppercase;
        position: relative;
        z-index: 5;
        @include transition;
        &:hover {
            color: $brown;
        }
    }
    &--excerpt {
        color: #505050;
        @include fluid-font(22px);
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-top: 1px solid #AFD9EB;
        border-bottom: 1px solid #AFD9EB;
    }
    &--info {
        @include bup(md){
            padding-left: 135px;
        }
    }
    &--details {
        & > div {
            position: relative;
            display: block;
            margin-bottom: .5rem;
            span {
                display: inline-block;
                color: #505050;
                @include fluid-font(16px);
                .colorbox {
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    margin-right: .5em;
                }
            }
            &.category {
                span {
                    vertical-align: middle;
                }
            }
            &.seats-left {
                color: #505050;
                .btn {
                    margin-left: .5rem;
                    &:hover {
                        background-color: $brown;
                        color: #fff;
                    }
                }
            }
        }
        .strong {
            font-weight: 700;
            font-family: $montserrat;
            color: $blue;
        }
        @include bdown(md){
            .strong {
                padding-right: .5rem;
            }
        }
        @include bup(md){
            span {
                display: block;
            }
            .strong {
                position: absolute;
                left: -140px;
                min-width: 135px;
                text-align: right;
                top: 50%;
                transform: translateY(-50%);
            }
        }

    }
    &--inner {
        height: 100%;
        padding: 1rem 2rem; 
        display: flex;
        @include bdown(md){
            display: block;
        }
    }
    .text-right {
        @include bdown(md){
            flex: 1 0 100%;
        }
    }
    .btn {
        border: 2px solid #D3CABB;
        white-space: nowrap;
        @include bdown(md){
            margin-top: 3rem;
        }
    }
    &:nth-child(odd){
        background-color: rgba(180, 180, 180, .12);
        // .hor-block--inner {
        //     transition: background-color .5s ease-in-out;
        //     background-color: rgba(180, 180, 180, .12);
        // }
        // &:hover {
        //     .hor-block--inner {
        //         transition: background-color .5s ease-in-out;
        //         background-color: rgba(180, 180, 180, .3);
        //     }
        // }
    }
    &:nth-child(even){
        background-color: #fff;
        // .hor-block--inner {
        //     transition: background-color .5s ease-in-out;
        // }
        // &:hover {
        //     .hor-block--inner {
        //         background-color: rgba(#fff, $alpha: .6);
        //     }
        // }
        .soc-share-container {
            .soc-share {
                a {
                    color: $green;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }


    .soc-share-container {
        justify-content: flex-end;
        @extend %share-style1;
        .soc-share {
            a {
                font-size: 1rem;
                height: 1rem;
                width: 1rem;
                padding: 0 1rem;
            }
        }
    }

    @include bdown(md){
        &.row {
            padding-bottom: 0;
        }
    }
}
.single-event {
    background-color: #D3CABB;
    .hor-block {
        border-bottom: 0;
        &--inner {
            background-color: transparent !important;
            padding-top: 0;
            padding-bottom: 0;
        }
        &--info {
            .btn {
                background-color: $blue;
                color: #fff;   
            }
            @include bup(md){
                padding-left: 0;
            }
            @include bdown(md){
                .btn {
                    margin-top: 1rem;
                    width: 100%;
                    justify-content: center;
                }
            }
        }
        &--title {
            &:hover {
                color: $blue;
            }
        }
        &--details {
            @include bup(md){
                padding-left: 130px;
            }
        }
        &--excerpt {
            padding: 0;
            border: none;
            @include fluid-font(19px);
            @include bup(md){
                max-width: 80%;
            }
        }
    }
    .bordered-img-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &--top {
        .col{
            &:first-child {
                flex-grow: 1;
                @include bdown(sm){
                    display: none;
                }
            }
            &:last-child {
                flex-grow: 0;
            }
        }
        .breadcrumbs {
            display: flex;
            color: $brown;
            @include fluid-font(22px);
            li{
                &:after {
                    content: "/";
                    display: inline-block;
                    padding: 0 .2em;
                }
                a {
                    display: inline-block;
                    color: $blue;
                }
                &:last-of-type {
                    &:after {
                        content: none;
                    }
                }

            }
        }
        .se-icons-list {
            display: flex;
            a.icon {
                font-size: 2rem;
                margin-right: 1.5rem;
                display: block;
                &:hover {
                    color: $brown;
                }
            }
            @include bup(md){
                justify-content: flex-end;
                a.icon {
                    margin-left: 1.5rem;
                    margin-right: 0;
                }
            }
        }
    }
}
.num-container {
    .col {
        &:nth-child(odd){
            .inner {
                background-color: $green;
            }
        }
        &:nth-child(even){
            .inner {
                background-color: $light-blue;
            }
        }
    }
    .inner {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .num {
        @include fluid-font(64px);
        font-weight: 700;
        color: #444;
        font-family: $poppins;
    }
    .num-info {
        color: #fff;
        @include fluid-font(21px);
        font-weight: 700;
    }
    @include bdown(md){
        flex-wrap: wrap;
        .col {
            flex: 0 0 50%;
        }
        .inner {
            flex-direction: column;
            padding: 5px;
        }
    }
    @include bdown(xxs){
        .col {
            flex: 0 0 100%;
        }
    }
}
.regular-hours {
    background-color: #eee;
    h4 {
        color: $blue;
    }
}
.about-us-top {
    margin-top: 1rem;
    p:nth-of-type(1){
        font-weight: 700;
        font-family: $poppins;
        @include fluid-font(24px);
    }
    p:nth-of-type(3),
    p:nth-of-type(4)
    {
        @include fluid-font(22px);
        font-weight: 700;
        font-family: $poppins;
    }
}
.download-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
        flex: 0 0 32%;
        margin-bottom: 2%;
        &:nth-child(odd){
            a {
                background-color: $green;
            }
        }
        &:nth-child(even){
            a {
                background-color: $light-blue;
            }
        }
        a {
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: .5rem .5rem .5rem 3rem;
            position: relative;
            @include fluid-font(17px);
            font-family: $poppins;
            font-weight: 700;
            &:before {
                position: absolute;
                display: block;
                content: "\e933";
                left: 1rem;
                top: 50%;
                transform: translateY(-50%);
                font-family: $icomoon;
                font-weight: normal;
                font-size: 1.4em;
            }
            &:hover {
                color: #fff;
                background-color: $blue;
            }
        }
    }
    @include bup(md){
        li {
            min-height: 4.35rem;
        }
    }
    @include bdown(md){
        li {
            flex: 0 0 49%;
        }
    }
    @include bdown(xxs){
        li {
            flex: 0 0 100%;
            a {
                min-height: 50px;
            }
        }
    }
}

.rate-notes {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include bup(md){
        .col {
            border-right: 1px solid $green;
            &:last-child {
                border-right: none;
            }
        }

    }
    &--info {
        color: $blue;
        font-weight: 700;
        font-family: $poppins;
        @include fluid-font(27px);
        position: relative;
        padding-left: 2rem;
        &:before {
            content: "\e93b";
            display: inline-flex;
            position: absolute;
            font-family: $icomoon;
            color: $green;
            top: 0;
            left:0;
        }

    }
}
.tile {
    &--list,
    &--header {
        display: flex;
        font-family: $poppins;
        li {
            flex: 1 1 0;
            display: flex;
            justify-content: center;
        }
    }
    &--header {
        @include fluid-font(25px);
        font-weight: 500;
        color: #777;
    }
    &--list {
        @include fluid-font(32px);
        font-weight: 700;
        color: $blue;
        li {
            border: 1px solid #FEFDF8;
            padding: 1.25rem;
        }
        &:nth-child(odd){
            li {
                background-color: rgba($green, $alpha: .1);
            }
        }
        &:nth-child(even){
            li {
                background-color: rgba($green, $alpha: .15);
            }
        }
    }
}
.card-link {
    background-color: $blue;
    color: #fff;
    padding: 1.5rem;
    .btn {
        display: inline-flex;
        align-items: center;
        height: 2.42rem;
        &:hover {
            background-color: $light-blue;
        }
        &:before {
            font-size: 1.7em;
        }
    }
    &--info {
        display: flex;
        align-items: center;
        font-family: $poppins;
        @include fluid-font(25px);
        font-weight: 700;
        text-transform: uppercase;
        @include letter-spacing(70);
        margin-bottom: 1rem;
        img {
            margin-right: .5rem;
        }
    }
}
section.cef-direct-list {
    ul {
        li {
            background-color: #F9FCF6;
            margin-bottom: 1rem;
            padding: 1.5rem 2.5rem 1.5rem 5rem !important;
            &:before {
                content: "\e93b";
                font-size: 3.5rem;
                top: 2rem;
                left: 1rem;
                display: block;
                transform: none;
            }
            b, h3 {
                color: $blue;
                @include fluid-font(32px);
                margin-bottom: .4em;
                font-family: $poppins;
            }
            .text {
                color: #444;
                @include fluid-font(22px);
            }
        }
    }
}
section.planning-outline {
    h3 {
        color: $blue;
        @include fluid-font(34px);
    }
    .row {
        justify-content: space-between;
    }
    .btn {
        @include fluid-font(24px);
        border-radius: 0;
        @include bup(md){
            width: 420px;
            &:before {
                padding-right: 2rem;
            }
        }
    }
}
.contact-info {
    color: #fff;
    font-family: $poppins;
    padding-top: 1rem;
    padding-left: 1rem;
    li {
        position: relative;
        padding-left: 2rem;
        margin-bottom: 2rem;
        @include fluid-font(35px);
        &:before {
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 1.2rem;
        }
    }
    &--title {
        color: $green;
        font-weight: 700;
        margin-bottom: .5rem;
    }
    a {
        &:hover {
            color: $green;
        }
    }
}
.map-container {
    iframe {
        width: 100%;
        height: 517px;
    }
}


.link-more {
    position: absolute;
    top: 50%;
    transform: tramslateY(-50%);
    display: inline-flex;
    align-items: center;
    @include fluid-font(14px);
    color: $blue;
    font-weight: 600;
    @include letter-spacing(200);
    &:after {
        font-family: $icomoon;
        content: "\e91e";
        display: inline-block;
        padding-left: .5rem;
    }
    &:hover {
        color: $brown;
    }
    @include bdown(md){
        position: static;
    }
    &.link-more--right {
        right: 15px;
    }
    &.link-more--left {
        left: 15px;
    }
    &--static {
        position: static;
    }
}
.arrowed-link {
    color: $blue;
    display: inline-flex;
    align-items: center;
    &:hover {
        color: $brown;
    }
    &:after {
        content: "\e91e";
        font-family: $icomoon;
        margin-left: .5rem;
    }
}
.back-link {
    color: #A2A0A0;
    &:before {
        color: $blue;
    }
    &:hover {
        color: $blue;
    }
}


section.fullwidth-banner {
    .banner-layout {
        background-size: auto 100%;
        background-repeat: no-repeat;
        position: relative;
        @extend %title-block;
        .container {
            z-index: 5;
            position: relative;
        }
        &.position-right {
            background-position: left top;
            &:after {
                position: absolute;
                content: "";
                top: 0;
                height: 100%;
                left: 0;
                width: 100%;
                background-image: linear-gradient(to left, #000, #000 35%, transparent 70%, transparent);
            }
            .container {
                text-align: right;
            }
        }
        &.position-left {
            background-position: right top;
            &:after {
                position: absolute;
                content: "";
                top: 0;
                height: 100%;
                left: 0;
                width: 100%;
                background-image: linear-gradient(to right, #000, #000 35%, transparent 70%, transparent);
            }
            .container {
                text-align: left;
            }
        }
        .btn-container {
            margin-top: 2rem;
        }
        .banner {
            &--info {
                text-align: left;
                display: inline-block;
                padding-left: 2em;
                padding-right: 2em;
                color: #fff;
                p {
                    font-family: $poppins;
                }
            }
            &--inner {
                padding: 3rem;
                margin-bottom: 1rem;
                line-height: 1;
                display: inline-block;
            }
        }
        & + .banner-layout {
            margin-top: 1rem;
        }
        @include bup(md){
            .banner--info {
                max-width: 815px;
                padding-top: 3em;
                padding-bottom: 3em;
            }
        }
        @include bdown(md){
            .banner--info {
                max-width: 50%;
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }
        @include bdown(sm){
            .banner--info {
                max-width: none !important;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &.coach-list {
        .banner-layout {
            .title-2 {
                color: $dark;
            }
        }
    }
}

.img-bordered-container {
    $width: 1.22rem;
    position: relative;
    &:before,
    &:after {
        content: "";
        background-color: $brown;
        display: block;
        position: absolute;
    }
    &:before{
        width: $width;
        left: -$width;
        top: 0;
        height: 80%;

    }
    &:after {
        width: 80%;
        left: -$width;
        top: -$width;
        height: $width;
    }

}


.aside {
    &--title {
        margin-bottom: 1em;
    }
    &--nav {
        @include fluid-font(25px);
        li {
            margin-bottom: 1em;
        }
        a {
            color: $blue !important;
            &:hover, &.active {
                color: $brown !important;
            }
        }
    }

    &--container {
        margin-bottom: 1.5rem;
    }
    &--banner {
        background-color: $brown;
        color: #fff;
        padding: 2rem 1rem;
        background-image: url(../../images/icons/envelope.png);
        background-repeat: no-repeat;
        background-position: 107% 107%;
        background-size: auto;
        @include fluid-font(15px);
        h4 {
            color: inherit;
            font-weight: 700;
            @include fluid-font(20px);
        }
        .btn {
            background-color: $orange;
            font-family: $oswald;
            @include letter-spacing(50);
            font-weight: 600;
            border-radius: 5px;
            &:hover {
                background-color: $blue;
            }
        }
    }
}


/***************************
******  BLOG Related  ******
***************************/
.news {
    padding: 0;
    .col {
        padding-top: 2em;
        padding-bottom: 2em;
    }
    .aside {
        background-color: rgba(#D3CABB, $alpha: .42);
        // flex: 0 0 326px;
        flex: 0 0 420px;
        // box-sizing: content-box;
        &-banner {
            .inner {
                padding-left: 30%;
                width: 100%;
                &:before {
                    content: none;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    max-width: 25%;
                }
            }
        }
        &--title {
            @include fluid-font(58px);
        }
    }
    @include bup(md){
        .aside {
            padding-left: 2rem;
            padding-right: 2rem;
            margin-left: 5%;
        }
    }
}
article {
    padding: 12px;
    margin-bottom: 52px;
    position: relative;
    @include bdown(md) {
        margin-bottom: 25px;
    }
    
    .article-header {
        color: $brown;
        @include transition;
        @include fluid-font(49px);
        @include letter-spacing(20);
        font-family: $oswald;
        font-weight: normal;
        text-align: left;
        margin-bottom: 1em;
        a {
            color: $brown;
            &:hover {
                color: $blue;
            }
        }
    }
    .article-inner {
        .date {
            span {
                font-family: $oswald;
                color: $blue;
                font-weight: 500;
                @include letter-spacing(39px);
                @include fluid-font(33px);
            }
        }
        .excerpt, .article-text {
            @include fluid-font(16px);
            font-family: $poppins;
            margin-top: 1.5em;
            max-width: 83%;
            line-height: 30px;
            @include bdown(md) {
                max-width: none;
                margin-top: 2em;
            }
        }
        .btn-container {
            .btn {
                @include fluid-font(14px);
                color: $blue;
                &:hover {
                    color: $brown;
                }
            }
        }
        .learn-more {
            font-family: $poppins;
        }
        .featured-media-container {
            &.video {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                iframe {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }

    }
    .link-more {
        font-family: $poppins;
    }
    .article-bottom {
        @include bup(md){
            .btn-container {
                float: left;
            }
            .soc-share-container {
                float: right;
            }
        }
    }
    &.article-item {
        .article-inner {
            border-bottom: 1px solid #707070;
            padding: 39px 0;
            .soc-share-container {
                float: right;
                @extend %share-style3;
            }
            .ssc-title {
                font-style: italic;
                color: #798889 !important;
            }
            @include bdown(md) {
                padding: 25px 3% 17px;
            }
        }
    }
    &.article-single {
        border: none;
        margin-bottom: 0;
        padding-top: 2.5em;
        .article-inner {
            max-width: 1100px;
            position: relative;
            .article-text {
                font-family: $montserrat;
                @include fluid-font(19px);
            }
            .date{
                padding-bottom: .5em;
                span {
                    color: $blue;
                    @include letter-spacing(100);
                }
            }
            @include bup(md) {
                padding-left: 6.5%;
                padding-right: 0;
            }
            @include block-center;
            h2.single-article-header {
                line-height: 1.2;
                text-align: left;
                margin-bottom: 1em;
                color: $brown;
            }
            .article-text {
                max-width: none;
                @include font-count(13, 18);
                p {
                    @include font-count(13, 18);
                }
            }
            .soc-share-wrapper {
                padding-bottom: 2em;
                border-bottom: 2px solid #5F5F5F;
            }
            .soc-share-container {
                padding-bottom: .5em;
                float: right;
                @extend %share-style3;
            }
        }
        .article-bottom {
            border-top: 2px solid rgba(95, 95, 95, .69);
        }
        .backlink-container {
            margin-bottom: 2em;
            margin-top: 2em;
            a {
                @include fluid-font(14px);
                @include letter-spacing(200);
                font-weight: 600;
                color: #A2A0A0;
                text-transform: uppercase;
                display: inline-flex;
                align-items: center;
                // font-family: $poppins;
                &:before {
                    color: $blue;
                }
                &:hover {
                    color: $blue;
                }
            }
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
        @include bup(nm){
            margin-left: -5%;
        }
    }
}
.pagination-container {
    text-align: center;
}
.pagination {
    display: inline-flex;
    align-items: center;
    padding-bottom: 20px;
    @include bdown(md) {
        display: flex;
        justify-content: space-between; 
    }
    .pagination-list {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        li {
            display: inline-block;
            margin-right: 7px;
            a {
                color: #363636;
                background-color: #D3CABB;
                padding: 20px;
                text-align: center;
                min-width: 55px;
                padding: 18px 30px;
                font-weight: 300;
                @include font-count(12,24);
                &:hover, &.active-pag {
                    background-color: $brown;
                    color: #fff;
                }
            }
        }
        @include bdown(md) {
            li {
                margin: 0 1px;
                a {
                    min-width: 0;
                    padding: 9px 15px;
                }
            }
        }
        @include bdown(xs) {
            li a {
                padding: 9px;
            }
        }
    }
    .pag-arrow {
        border-radius: 5px;
        color: $brown;
        font-size: 2em;
        // border: 2px solid transparent;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        // img {
        //     width: 1em;
        //     height: 1em;
        // }
        &:hover {
            color: $blue;
        }
        &:first-of-type {
            margin-right: 20px;
        }
        &:last-of-type {
            margin-left: 20px;
        }
        @include bdown(md) {
            &:first-of-type {
                margin-right: 7px;
            }
            &:last-of-type {
                margin-left: 7px;
            }
        }
        @include bdown(xs) {
            min-width: 30px;
            padding: 5px;
            &:first-of-type {
                margin-right: 5px;
            }
            &:last-of-type {
                margin-left: 5px;
            }
        }
    }
    &.single-pagination {
        display: flex;
        .pagination-list {
            justify-content: space-between;
        }
    }
}
.featured-media-container.video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.media-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.soc-share-container {
    padding-top: .5em;
    padding-right: 2%;
    @include bdown(md) {
        max-width: 190px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 25px;
        float: none;
        padding-right: 0;
    }
    .ssc-title {
        @include fluid-font(19px);
        color: $blue;
        margin-bottom: 5px;
    }
    ul {
        display: flex;
        justify-content: space-between;
        li {
            a {
                color: $blue;
                @include transition;
                @include fluid-font(20px);
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}

.search-item {
    background-color: rgba(211,202,187,.9);
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    h2 {
        @include fluid-font(25px);
        color: $brown;
        font-family: $poppins;
    }
    .btn {
        @include letter-spacing(120);
        margin-top: 2rem;
    }
}

