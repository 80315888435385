footer {
    color: #fff;
    h4 {
        font-family: $lora;
        color: $brown;
        @include font-count(20, 30);
        margin-top: 0;
        margin-bottom: .4em;
        @include bup(md) {
            line-height: 40px;
        }
    }
    a {
        color: #e7e7e7;
        &:hover, &.active {
            color: $brown;
        }
    }
    .footer-top {
        background-color: #19262C;
        .container {
            padding-top: 5rem;
            padding-bottom: 2.2rem;
            position: relative;
            .footer-social-links {
                padding-top: 1.5em;
                display: flex;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
                li {
                    flex: 0 1 auto;
                    margin-right: 5px;
                    position: relative;
                    a {
                        position: relative;
                        border: 3px solid;
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                        &:before {
                            position: absolute;
                            display: block;
                            font-size: 24px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            @include transition;
                        }
                        border-color: $blue;
                        &:hover {
                            &:before {
                                color: #fff;
                            }
                        }
                        &.icon-facebook {
                            color: #3B5998;
                            border-color: #3B5998;
                            &:hover {
                                background-color: #3B5998;
                            }
                        }
                        &.icon-twitter {
                            color: #49C9F2;
                            border-color: #49C9F2;
                            &:hover {
                                background-color: #49C9F2;
                            }
                        }
                        &.icon-instagram {
                            color: #3B749F;
                            border-color: #3B749F;
                            &:hover {
                                background-color: #3B749F;
                            }
                        }
                        &.icon-youtube {
                            color: #CE332D;
                            border-color: #CE332D;
                            &:hover {
                                background-color: #CE332D;
                            }
                        }
                        &.icon-linkedin {
                            color: #0775B7;
                            border-color: #0775B7;
                            &:hover {
                                background-color: #0775B7;
                            }
                        }

                    }
                }
            }
            .row {
                flex-wrap: wrap;
                .col {
                    // border-left: 3px solid rgba($color: $light-blue, $alpha: .19);
                    &:nth-child(1){
                        flex: 0 0 27%;
                    }
                    &:nth-child(2) {
                        flex: 0 0 17%;
                    }
                    &:nth-child(3) {
                        flex: 0 0 24%;
                    }
                    &:nth-child(4) {
                        flex: 0 0 32%;
                        padding-left: 5%;
                    }
                    .logo-container {
                        margin-bottom: 2rem;
                    }
                    .footer-about {
                        font-weight: 500;
                        color: $brown;
                        @include rlh(26px);
                        margin-bottom: 1rem;
                    }
                    .footer-contacts {
                        @include rlh(26px);
                        &--title {
                            font-family: $lora;
                            @include fluid-font(22px);
                            color: #D3CABB;
                        }
                        .icon {
                            position: relative;
                            padding-left: 2rem;
                            display: inline-block;
                            line-height: inherit;
                            &:before {
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                color: $green;
                            }
                        }
                        a.icon-phone {
                            @include fluid-font(26px);
                            color: $brown;
                            &:before {
                                padding-right: .5em;
                            }
                        }
                        .btn {
                            color: #fff;
                            @include letter-spacing(200);
                        }
                    }
                    .footer-quick-links {
                        text-transform: uppercase;
                        font-family: $oswald;
                        font-weight: 600;
                        @include fluid-font(22px);
                        display: inline-block;
                        text-align: left;
                        li {
                            margin-bottom: .6em;
                            &:nth-last-child(3) {
                                margin-top: 3rem;
                            }
                        }
                    }
                    .footer-icon-links {
                        a {
                            display: flex;
                            align-items: center;
                            margin-bottom: 2.5rem;
                            font-size: 1.5rem;
                            font-family: $montserrat;
                            justify-content: flex-start;
                            i {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 5px;
                                width: 3.9rem;
                                height: 3.9rem;
                                flex: 0 0 3.9rem;
                                background-color: $gray;
                                color: $blue;
                                font-size: 1.5rem;
                                @include transition;
                            }
                            &:hover {
                                i {
                                    background-color: $blue;
                                    color: $gray;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-bottom {
        background-color: #D3CABB;
        color: $blue;
        font-family: $montserrat;
        font-size: 12px;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        font-weight: 500;
        p {
            margin-bottom: 0;
            @include letter-spacing(200);
        }
    }
    @include bup(md){
        .footer-top {
            .col {
                &:nth-child(2),
                &:nth-child(3) {
                    border-right: 1px solid #494949;
                }
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    margin-top: 3rem;
                }
            }
            .footer-contacts .icon {
                margin-bottom: 2rem;
            }
        }
    }
    @include bdown(md) {
        .footer-top .container {
            .row {
                display: flex;
                .col {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    &:nth-child(1) {
                        flex-basis: 100%;
                    }
                    &:nth-child(2) {
                        flex-basis: 50%;
                    }
                    &:nth-child(3) {
                        flex-basis: 50%;
                    }
                    &:nth-child(4) {
                        flex-basis: 100%;
                    }
                    .footer-social-links {
                        padding-left: 0;
                        justify-content: center;
                    }
                }
            }
            .logo-container, .footer-about {
                text-align: center;
                margin-bottom: 1rem;
            }
        }
    }
}