section.cf {
    position: relative;
    background-position: right 2rem;
    background-repeat: no-repeat;
    &--right {
        background-position: left 2rem;
        form {
            margin-left: auto;
        }
    }
    &:after {
        content: "";
        background-image: linear-gradient(rgba($color: #F6F7F5, $alpha: 1 ), rgba($color: #EDEFEC, $alpha: 1 ), rgba($color: #DFE1DE, $alpha: 0 ));
        position: absolute;
        left: 0;
        top: 0;
        height: 30%;
        width: 100%;
    }
    &.clear {
        background-position: right top;
        &:after {
            content: none;
        }
    }
    form {
        position: relative;
        z-index: 5;
    }
}

form {
    input, select, textarea {
        &.has-danger {
            border: 1px solid #f00 !important;
        }
    }
    .thankyou-url {
        display: none;
    }
    .required {
        color: #f00;
    }
}
.footer-top {
    form.hsf {
        ::placeholder {
            color: $blue;
        }
        @include bup(md) {
            padding: 2rem 4rem;
            max-width: none;
            margin-left: auto;
            margin-right: auto;
        }
        input, select, textarea {
            color: $blue;
        }
        .btn {
            font-family: $oswald;
            font-size: 12px;
            font-weight: 700;
            @include letter-spacing(150);
            padding: 1rem;
        }
    }
}
form.hsf {
    @extend %letter-form;
    @include bup(xs){
        padding-left: 20%;
        background-image: url(../../images/icons/form-question.svg);
        background-repeat: no-repeat;
        background-position: left center;
        background-color: #fff;
        &#ask-a-question{
            background-color: transparent;
        }
        background-size: 15%;
    }
    ::placeholder {
        text-transform: uppercase;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        transition: background-color 5000s ease-in-out 0s;
        // -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .hsf-pretitle {
        font-family: $lora;
        @include fluid-font(26px);
        color: $brown;
        font-weight: 700;
        padding-bottom: .3rem;
        border-bottom: 1px solid #929292;
        display: inline-block;
    }
    // input::-internal-autofill-selected {
    //     background-color: transparent !important;
    // }

    label {
        color: $blue;
        display: block;
    }
    input, select, textarea {
        // color: $violet;
        // text-transform: uppercase;
    }
    .col {
        margin-bottom: 1rem;
    }
    .bottom-info {
        color: #707070;
        font-size: 14px;
    }
    .form-inner {
        @extend %block-shadow-1;
    }
    button.link-more {
        background-color: transparent;
        border: none;
        padding-top: .5rem;
        @include fluid-font(18px);
    }
}
form#login-form{
    @extend %letter-form;
    .row {
        margin-bottom: 1rem;
    }
    .col {
        align-items: center;
        &.vcenter {
            display: inline-flex;
            margin-bottom: 5px;
        }
    }
    label {
        font-family: $montserrat;
        @include fluid-font(18px);
        font-weight: 700;
        @include letter-spacing(100);
    }
    input, select, textarea {
        background-color: #fff;
        padding: 0 1rem;
        font-weight: 700;
        @include fluid-font(18px);
        border-color: transparent;
        font-family: $montserrat;
    }
    input {
        line-height: 45px;
    }
    .btn {
        background-color: $blue;
        &:hover {
            background-color: $green;
        }
    }

}
form.default-form {
    @extend %default-form;
}
form.search-catalogue {
    h2 {
        margin-bottom: 0;
    }
    .search {
        border: 2px solid #B4B4B4;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    input {
        max-width: 90%;
        flex: 1 1 90%;
        border: none;
        background-color: transparent;
        font-weight: 500;
        color: #A50C0C;
        @include fluid-font(25px);
        padding: .5em;
        flex: 1 1 0;
        height: 2.4em;
    }
    ::placeholder {
        color: #A50C0C;
    }
    button {
        flex: 0 0 10%;
        border: none;
        background-color: #D3CABB;
        color: #fff;
        font-size: 1.2em;
    }
}
form.library-comment-card {
    @extend %default-form;
    input, select, textarea {
        background-color: #EBF5E2;
    }
    label {
        color: $blue;
    }
    .quantity-block {
        max-width: 1040px;
        margin-left: auto;
        margin-right: auto;
        .col {
            &:first-of-type{
                display: flex;
                align-items: center;
                border-top: solid #A2E5F9 1px;
                border-bottom: solid #A2E5F9 1px;
            }
            &:last-of-type{
                flex: 0 0 30%;
            }
        }

        label {
            font-family: $montserrat;
            font-weight: 700;
            @include fluid-font(32px);
        }
        @include bdown (md){
            .row {
                margin-bottom: 40px;
            }
            label {
                padding: 1rem !important;
            }
        }
    }
}
form.ask-your-librarian {
    @extend %default-form;
    .row {
        .col {
            label {
                color: #525252;
                @include fluid-font(19px);
                .sub {
                    font-family: $lora;
                    font-style: italic;
                    display: block;
                    @include fluid-font(20px);
                }
            }
            input, select, textarea {
                border: 1px solid #9B918A;
            }
            textarea {
                background-color: #eae5dc
            }
        }
    }
}
form.volunteer-form {
    .row .col {
        textarea {
            background-color: #EAE5DC;
        }
    }
}
form.register.default-form {
    margin-top: 2rem;
    .btn {
        @include bup(md){
            margin-left: 2rem;
        }
    }
    .row .col {
        margin-bottom: 0 !important;
        .select-wrapper {
            background-color: #F3F2F5!important;
            border-radius: 5px;
            overflow: hidden;
            &:before {
                content: "\e920";
                font-family: $icomoon;
                font-size: 2em;
                color: #D3CABB;
            }
            &:after {
                content: none;
            }
        }
    }
}
.pencil {
    .col {
        position: relative;
        &:after {
            position: absolute;
            top: 1.75rem;
            transform: translateY(-50%);
            content: "\e92d";
            right: 2rem;
            font-family: $icomoon;
            // color: $violet;
        }
    }

}